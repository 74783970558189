import React from 'react';
import useCalculationResult from '../../hooks/useCalculationResult';

export default function PaymentLead() {
  const { total } = useCalculationResult();
  return (
    <section>
      <h1>
        Offset your <br />
        emissions
      </h1>

      <p className="t-lead">
        Time to act! Offset some, all, or more than your footprint.
      </p>

      <p className="t-lead">
        Your calculated yearly emissions are{' '}
        {total ? <strong>{total} tCO₂e</strong> : null}.
      </p>
    </section>
  );
}
