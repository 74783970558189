import React, { useState } from 'react';

import CalculatorSteps from '../../components/CalculatorSteps';
import RegisterForUpdates from './RegisterForUpdates';
import OffsetSelection from './OffsetSelection';
import OffsetAmountPicker from './OffsetAmountPicker';
import PaymentLead from './PaymentLead';
import Spacer from '../../components/Spacer';

export enum OffsetMethod {
  ETS = 'ETS',
  Carbfix = 'Carbfix',
}

export const offsetPrices = {
  [OffsetMethod.ETS]: 6250,
  [OffsetMethod.Carbfix]: 6800,
};

export default function Payment() {
  const [offsetPercent, setOffsetPercent] = useState(100);
  const [selectedMethod, setSelectedMethods] = useState(OffsetMethod.ETS);
  return (
    <>
      <CalculatorSteps step={2} />
      <div className="content">
        <PaymentLead />

        <Spacer spaces={4} />

        <OffsetAmountPicker
          offsetPercent={offsetPercent}
          setOffsetPercent={setOffsetPercent}
        />

        <Spacer spaces={6} />

        <OffsetSelection
          offsetPercent={offsetPercent}
          selectedMethod={selectedMethod}
          setSelectedMethods={setSelectedMethods}
        />

        <Spacer spaces={6} />

        <RegisterForUpdates
          offsetMethod={selectedMethod}
          offsetPercent={offsetPercent}
        />

        <Spacer spaces={8} />
      </div>
    </>
  );
}
