import React, { ReactNode, useState } from 'react';
import {
  Collapse,
  Form,
  Input,
  Select,
  Button,
  Radio,
  InputNumber,
  Checkbox,
  Popover,
  Tooltip,
} from 'antd';

import {
  MinusCircleOutlined,
  PlusOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { isNull } from 'lodash';

import useCalculatorState, {
  AutomobileSize,
  AutomobileFuelType,
  SeatClass,
  EmissionCategory,
  DietInfo,
  initialAutomobileValues,
  getInitialAviationValues,
} from '../hooks/useCalculatorState';

import useCalculationResult from '../hooks/useCalculationResult';

import styles from './Form.module.css';
import useDebounce from '../hooks/useDebounce';
import { gql, useQuery } from '@apollo/client';

const { Panel } = Collapse;

type PanelHeaderProps = {
  title: string;
  openPanel?: () => void;
  closePanel?: () => void;
  notCustomizable?: boolean;
  info?: ReactNode;
};
const PanelHeader = ({
  title,
  openPanel,
  closePanel,
  info,
}: PanelHeaderProps) => (
  <>
    <h4 style={{ margin: '0 1rem 0 0', display: 'inline-block' }}>
      {title}
      {info ? (
        <Popover
          content={<p style={{ maxWidth: 200, marginBottom: 0 }}>{info}</p>}
          // title="Title"
          trigger="hover"
        >
          <InfoCircleOutlined style={{ marginLeft: '.25rem' }} />
        </Popover>
      ) : null}
    </h4>
    {/* {subtitle ? <Text type="secondary">{subtitle}</Text> : null} */}

    <Radio.Group name="flights" defaultValue="averageUse">
      {openPanel && closePanel ? (
        <>
          <Radio.Button value="averageUse" onClick={closePanel}>
            The average Icelander
          </Radio.Button>
          <Radio.Button value="noUse" onClick={openPanel}>
            My information
          </Radio.Button>
        </>
      ) : (
        <Tooltip
          placement="bottom"
          title="Ekki er hægt að skipta þessum flokki upp að svo stöddu"
        >
          <Radio.Button disabled value="averageUse">
            The average Icelander
          </Radio.Button>
        </Tooltip>
      )}
    </Radio.Group>
  </>
);

const GET_CITIES = gql`
  query getCities($searchString: String) {
    locations {
      cities(searchString: $searchString, limit: 10) {
        id
        city
        country
      }
    }
  }
`;

const defaultCityList = [
  {
    id: '16',
    city: 'Keflavik',
    country: 'Iceland',
  },
  {
    id: '18',
    city: 'Reykjavik',
    country: 'Iceland',
  },
  {
    id: '11',
    city: 'Akureyri',
    country: 'Iceland',
  },
  {
    id: '617',
    city: 'Copenhagen',
    country: 'Denmark',
  },
  {
    id: '738',
    city: 'Stockholm',
    country: 'Sweden',
  },
  {
    id: '548',
    city: 'London',
    country: 'United Kingdom',
  },
  {
    id: '1386',
    city: 'Paris',
    country: 'France',
  },
  {
    id: '1057',
    city: 'Tenerife',
    country: 'Spain',
  },
  {
    id: '7729',
    city: 'New York',
    country: 'United States',
  },
  {
    id: '3726',
    city: 'Seattle',
    country: 'United States',
  },
];
type CitySelectProps = {
  label: ReactNode;
  name: string | (string | number)[];
  fieldKey: string | (string | number)[];
  onChange: (value: string) => void;
};

const CitySelect = ({ label, name, fieldKey, onChange }: CitySelectProps) => {
  const [searchString, setSearchString] = useState<string>('');
  const debouncedSearch = useDebounce<string>(searchString, 150);

  const { data } = useQuery(GET_CITIES, {
    variables: { searchString: debouncedSearch },
  });

  const searchOptions: { id: string; city: string; country: string }[] =
    data?.locations?.cities || [];

  const options = searchString ? searchOptions : defaultCityList;

  return (
    <Form.Item name={name} fieldKey={fieldKey} label={label}>
      <Select
        showSearch
        onSearch={(value) => setSearchString(value)}
        filterOption={false}
        placeholder="Type to search"
        options={options.map(({ id, city, country }) => ({
          label: `${city}, ${country}`,
          value: id,
        }))}
        onChange={onChange}
      />
    </Form.Item>
  );
};

const FlightsPanel = () => {
  const { state } = useCalculatorState();
  const initialValues = getInitialAviationValues(state.adults + state.children);

  const {
    addAviationLine,
    removeAviationLine,
    updateAviationLine,
  } = useCalculatorState();

  const addLine = (add: () => void) => () => {
    addAviationLine();
    add();
  };
  const removeLine = (remove: (index: number) => void, index: number) => () => {
    removeAviationLine(index);
    remove(index);
  };
  return (
    <Form>
      <Form.List name="aviation">
        {(fields, { add, remove }) => (
          <>
            {fields.length === 0 ? (
              <p>
                No flight recorded. If you have flown in the last twelve months
                please insert information by clicking on 'Add flight'
              </p>
            ) : null}
            {fields.map((field, index) => (
              <div className={styles.wrapper} key={field.key}>
                <CitySelect
                  label="Flight from"
                  name={[field.name, 'from']}
                  fieldKey={[field.fieldKey, 'from']}
                  onChange={(value: string) =>
                    updateAviationLine({ index, from: value })
                  }
                />
                <CitySelect
                  label="To"
                  name={[field.name, 'to']}
                  fieldKey={[field.fieldKey, 'to']}
                  onChange={(value: string) =>
                    updateAviationLine({ index, to: value })
                  }
                />

                <Form.Item
                  label="Seat class"
                  name={[field.name, 'seatClass']}
                  fieldKey={[field.fieldKey, 'seatClass']}
                  initialValue={initialValues.seatClass}
                >
                  <Select
                    onChange={(value: SeatClass) =>
                      updateAviationLine({ index, seatClass: value })
                    }
                  >
                    <Select.Option value={SeatClass.Economy}>
                      Economy
                    </Select.Option>
                    <Select.Option value={SeatClass.Business}>
                      Business Class
                    </Select.Option>
                    <Select.Option value={SeatClass.First}>
                      First Class
                    </Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Number of seats"
                  name={[field.name, 'numberOfSeats']}
                  fieldKey={[field.fieldKey, 'numberOfSeats']}
                  initialValue={initialValues.numberOfSeats}
                >
                  <InputNumber
                    min={1}
                    parser={(v) =>
                      v && !isNaN(parseInt(v, 10)) ? parseInt(v, 10) : 1
                    }
                    onChange={(value) =>
                      updateAviationLine({
                        index,
                        numberOfSeats: value as number,
                      })
                    }
                  />
                </Form.Item>

                <Checkbox
                  defaultChecked={initialValues.returnFlight}
                  name="returnFlight"
                  style={{ alignSelf: 'flex-end', marginBottom: '1rem' }}
                  onChange={(e) => {
                    console.log({ index, returnFlight: e.target.checked });
                    updateAviationLine({
                      index,
                      returnFlight: e.target.checked,
                    });
                  }}
                >
                  Return flight
                </Checkbox>

                {/* {fields.length > 1 ? ( */}
                <MinusCircleOutlined
                  className="dynamic-delete-button"
                  style={{
                    margin: '0 0 1.3rem auto',
                    alignSelf: 'flex-end',
                  }}
                  onClick={removeLine(remove, field.name)}
                />
                {/* ) : null} */}
              </div>
            ))}

            <Form.Item>
              <Button type="dashed" onClick={addLine(add)} block>
                <PlusOutlined /> Add flight
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  );
};

const PeriodMultiplicationFactors = ({
  onChange,
}: {
  onChange: (value: number) => void;
}) => (
  <Select defaultValue={1} className="select-after" onChange={onChange}>
    <Select.Option value={1}>km per year</Select.Option>
    <Select.Option value={12}>km per month</Select.Option>
    <Select.Option value={52}>km per week</Select.Option>
  </Select>
);

const AutomobilePanel = () => {
  const {
    setPeriodMultiplicationFactor,
    addAutomobileLine,
    removeAutomobileLine,
    updateAutomobileLine,
  } = useCalculatorState();

  const onPMFChange = (index: number) => (value: number) =>
    setPeriodMultiplicationFactor(index, value);
  const addLine = (add: () => void) => () => {
    addAutomobileLine();
    add();
  };
  const removeLine = (remove: (index: number) => void, index: number) => () => {
    removeAutomobileLine(index);
    remove(index);
  };
  return (
    <Form>
      <Form.List name="automobiles">
        {(fields, { add, remove }) => (
          <>
            {fields.length === 0 ? (
              <p>
                No car use recorded. If you have used a car in the 12 months
                please insert information by clicking on 'Add car'
              </p>
            ) : null}
            {fields.map((field, index) => (
              <div className={styles.wrapper} key={field.key}>
                <Form.Item
                  name={[field.name, 'automobileSize']}
                  fieldKey={[field.fieldKey, 'automobileSize']}
                  label={index === 0 ? 'Car size' : null}
                  initialValue={initialAutomobileValues.automobileSize}
                >
                  <Select
                    // @ts-ignore
                    onChange={(value) =>
                      updateAutomobileLine({
                        index,
                        automobileSize: value as AutomobileSize,
                      })
                    }
                  >
                    <Select.Option value={AutomobileSize.Small}>
                      Small
                    </Select.Option>
                    <Select.Option value={AutomobileSize.Medium}>
                      Medium
                    </Select.Option>
                    <Select.Option value={AutomobileSize.Large}>
                      Large
                    </Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  label={index === 0 ? 'Fuel type' : null}
                  name={[field.name, 'vehicleFuelType']}
                  fieldKey={[field.fieldKey, 'vehicleFuelType']}
                  initialValue={initialAutomobileValues.fuelType}
                >
                  <Select
                    onChange={(value) =>
                      updateAutomobileLine({
                        index,
                        fuelType: value as AutomobileFuelType,
                      })
                    }
                  >
                    <Select.Option value={AutomobileFuelType.Petrol}>
                      Gasoline
                    </Select.Option>
                    <Select.Option value={AutomobileFuelType.Diesel}>
                      Diesel
                    </Select.Option>
                    <Select.Option value={AutomobileFuelType.Electric}>
                      Electric
                    </Select.Option>
                    <Select.Option value={AutomobileFuelType.Hybrid}>
                      Hybrid
                    </Select.Option>
                    <Select.Option value={AutomobileFuelType.PHEV}>
                      Plug-in Hybrid
                    </Select.Option>
                    <Select.Option value={AutomobileFuelType.Biomethane}>
                      Methane
                    </Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  label={
                    index === 0 ? (
                      <>
                        Driving{' '}
                        <Popover
                          content={
                            <p style={{ maxWidth: 200, marginBottom: 0 }}>
                              Average car use in Iceland is 12.000 km per year
                            </p>
                          }
                          // title="Title"
                          trigger="hover"
                        >
                          <InfoCircleOutlined
                            style={{ marginLeft: '.25rem' }}
                          />
                        </Popover>
                      </>
                    ) : null
                  }
                  name={[field.name, 'kmDriven']}
                  fieldKey={[field.fieldKey, 'kmDriven']}
                  initialValue={initialAutomobileValues.kmDriven}
                >
                  <Input
                    addonAfter={
                      <PeriodMultiplicationFactors
                        onChange={onPMFChange(index)}
                      />
                    }
                    onChange={(e) =>
                      updateAutomobileLine({
                        index,
                        kmDriven: parseInt(e.target.value, 10),
                      })
                    }
                  />
                </Form.Item>

                {/* {fields.length > 1 ? ( */}
                <MinusCircleOutlined
                  className="dynamic-delete-button"
                  style={{ margin: '0 0 1rem 1rem', alignSelf: 'flex-end' }}
                  onClick={removeLine(remove, field.name)}
                />
                {/* ) : null} */}
              </div>
            ))}

            <Form.Item>
              <Button type="dashed" onClick={addLine(add)} block>
                <PlusOutlined /> Add car
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  );
};

const layout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 8 },
};
const DietPanel = () => {
  const {
    state: { diet },
    setDietInfo,
  } = useCalculatorState();
  return (
    <>
      <h5>
        How many portions of the following categories are consumed per week in
        your home?
      </h5>
      <Form
        initialValues={diet}
        onValuesChange={(_, dietInfo) => setDietInfo(dietInfo as DietInfo)}
      >
        <Form.Item
          {...layout}
          label={
            <>
              Red meat{' '}
              <Popover
                content={
                  <p style={{ maxWidth: 200, marginBottom: 0 }}>
                    Lamb and beef are examples of red meat. One portion is
                    approximately 150g
                  </p>
                }
                // title="Title"
                trigger="hover"
              >
                <InfoCircleOutlined style={{ marginLeft: '.25rem' }} />
              </Popover>
            </>
          }
          name="redMeatPPW"
          fieldKey="redMeatPPW"
          style={{ maxWidth: '100%' }}
        >
          <InputNumber
            parser={(v) => (v && !isNaN(parseInt(v, 10)) ? parseInt(v, 10) : 0)}
            min={0}
          />
        </Form.Item>
        <Form.Item
          {...layout}
          label={
            <>
              Poultry and pork{' '}
              <Popover
                content={
                  <p style={{ maxWidth: 200, marginBottom: 0 }}>
                    One portion is approximately 150g
                  </p>
                }
                // title="Title"
                trigger="hover"
              >
                <InfoCircleOutlined style={{ marginLeft: '.25rem' }} />
              </Popover>
            </>
          }
          name="whiteMeatPPW"
          fieldKey="whiteMeatPPW"
          style={{ maxWidth: '100%' }}
        >
          <InputNumber
            parser={(v) => (v && !isNaN(parseInt(v, 10)) ? parseInt(v, 10) : 0)}
            min={0}
          />
        </Form.Item>
        <Form.Item
          {...layout}
          label={
            <>
              Seafood{' '}
              <Popover
                content={
                  <p style={{ maxWidth: 200, marginBottom: 0 }}>
                    One portion is approximately 200g
                  </p>
                }
                // title="Title"
                trigger="hover"
              >
                <InfoCircleOutlined style={{ marginLeft: '.25rem' }} />
              </Popover>
            </>
          }
          name="fishPPW"
          fieldKey="fishPPW"
          style={{ maxWidth: '100%' }}
        >
          <InputNumber
            parser={(v) => (v && !isNaN(parseInt(v, 10)) ? parseInt(v, 10) : 0)}
            min={0}
          />
        </Form.Item>

        <Form.Item
          {...layout}
          label="Do you consume dairy?"
          name="consumesDairy"
          fieldKey="consumesDairy"
        >
          <Select>
            <Select.Option value={1}>Yes</Select.Option>
            <Select.Option value={0}>No</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </>
  );
};

const HorsesPanel = () => {
  const {
    setNumberOfHorses,
    state: { animals },
  } = useCalculatorState();
  return (
    <>
      <Form
        initialValues={animals}
        onValuesChange={({ horses }) => setNumberOfHorses(horses)}
      >
        <Form.Item
          label="How many horses do you use/own"
          {...layout}
          name="horses"
          fieldKey="horses"
          style={{ maxWidth: '100%' }}
        >
          <InputNumber min={0} precision={0} />
        </Form.Item>
      </Form>
    </>
  );
};
export default function DetailedForm() {
  const {
    aviation,
    automobiles,
    diet,
    animals,
    waste,
    transportIndustryServices,
  } = useCalculationResult();
  const {
    setCategoryCustomization,
    state: { openPanels },
  } = useCalculatorState();

  const openPanel = (key: EmissionCategory) => () => {
    setCategoryCustomization(key, true);
  };
  const closePanel = (key: EmissionCategory) => () => {
    setCategoryCustomization(key, false);
  };

  return (
    <section className={styles.detailedForm}>
      <Collapse
        // @ts-ignore
        activeKey={openPanels}
        ghost
        // onChange={setOpenPanels}
        expandIcon={() => null}
      >
        <Panel
          header={
            <PanelHeader
              title="Flights"
              openPanel={openPanel(EmissionCategory.Aviation)}
              closePanel={closePanel(EmissionCategory.Aviation)}
            />
          }
          key={EmissionCategory.Aviation}
          extra={!isNull(aviation) ? `${aviation} tCO₂e` : ''}
        >
          <FlightsPanel />
        </Panel>
        <Panel
          header={
            <PanelHeader
              title="Cars"
              openPanel={openPanel(EmissionCategory.Automobiles)}
              closePanel={closePanel(EmissionCategory.Automobiles)}
            />
          }
          key={EmissionCategory.Automobiles}
          extra={!isNull(automobiles) ? `${automobiles} tCO₂e` : ''}
        >
          <AutomobilePanel />
        </Panel>
        <Panel
          header={
            <PanelHeader
              title="Diet"
              openPanel={openPanel(EmissionCategory.Diet)}
              closePanel={closePanel(EmissionCategory.Diet)}
            />
          }
          extra={!isNull(diet) ? `${diet} tCO₂e` : ''}
          key={EmissionCategory.Diet}
        >
          <DietPanel />
        </Panel>
        <Panel
          header={
            <PanelHeader
              title="Horse Ownership"
              openPanel={openPanel(EmissionCategory.Animals)}
              closePanel={closePanel(EmissionCategory.Animals)}
            />
          }
          key={EmissionCategory.Animals}
          extra={!isNull(animals) ? `${animals} tCO₂e` : ''}
        >
          <HorsesPanel />
        </Panel>
        <Panel
          header={
            <PanelHeader
              title="Waste"
              info="Emissions from landfill and recycling"
            />
          }
          key={'5'}
          extra={!isNull(waste) ? `${waste} tCO₂e` : ''}
        ></Panel>
        <Panel
          header={
            <PanelHeader
              title="Other"
              info="Services, Transportation of Goods and Industry"
            />
          }
          key="6"
          extra={
            transportIndustryServices
              ? `${transportIndustryServices} tCO₂e`
              : ''
          }
        ></Panel>
      </Collapse>
    </section>
  );
}
