import { v4 as uuid } from 'uuid';

import useLocalStorage from './useLocalStorage';

const someUuid = uuid();
const idKey = 'nzUserId';

export default function useUserId() {
  const [id] = useLocalStorage<string>(idKey, someUuid);

  return id;
}
