import React, { ReactNode } from 'react';

import styles from './AvatarRow.module.css';
import Avatar from 'antd/lib/avatar/avatar';

type PersonProps = {
  name: ReactNode;
  role: ReactNode;
  src: string;
};

const Person = ({ name, role, src }: PersonProps) => (
  <div className={styles.person}>
    <Avatar size={128} src={src} />
    <h6>{name}</h6>
    <p>{role}</p>
  </div>
);

export default function AvatarRow() {
  return (
    <div className={styles.row}>
      <Person
        name="Gunnar Sturla Ágústuson"
        role={
          <>
            Front-end developer @&nbsp;
            <a
              href="https://klappir.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Klappir Green Solutions
            </a>
          </>
        }
        src="/img/gunnar.jpg"
      />
      <Person
        name="Halldór Reynir Tryggvason"
        role={
          <>
            Back-end developer @&nbsp;
            <a
              href="https://klappir.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Klappir Green Solutions
            </a>
          </>
        }
        src="/img/halli.jpg"
      />
      <Person
        name="Marissa Sigrún Pinal"
        role={
          <>
            Student of Environment and Natural Resources @&nbsp;
            <a
              href="https://www.hi.is/umhverfis_og_audlindafraedi"
              target="_blank"
              rel="noopener noreferrer"
            >
              University of Iceland
            </a>
          </>
        }
        src="/img/marissa.jpg"
      />
    </div>
  );
}
