import React from 'react';
import './Rationale.less';

export default function Rationale() {
  return (
    <div className="content">
      <h1>Calculations and Assumptions</h1>
      <p>
        The carbon calculator is based on Iceland's 2018 National Inventory
        Report
        <sup>
          <a href="https://unfccc.int/documents/225486">[1]</a>
        </sup>
        , its most recent publication. We use the reported emissions as a basis
        for most of NetZero's emission calculations, except for user-inputted
        flights and car usage. There are some emission categories in the report
        that we excluded from the calculator. They are aluminum and ferroalloy
        production, emissions from aviation fuel that can be traced to
        non-Icelandic citizens, and land use (
        <abbr title="Land Use, Land-Use Change and Forestry">LULUCF</abbr>)
        emissions.
      </p>
      <p>
        The annual emissions are divided between all of Iceland's inhabitants.
        However, they are not equally distributed—people under the age of 18
        count half as much as adults in the calculator. The rationale for this
        is twofold. People under the age of 18 don't have the same freedom to
        shape their lifestyles. Also, we speculate that children have a lower
        carbon footprint on average than adults. To adjust for this, we used the
        following number for Iceland's population in all average calculations:
        <i>people under 18 / 2 + people over 18.</i>
      </p>

      <h2>Aviation</h2>
      <h4>Average Icelander</h4>
      <p>
        Data from the National Inventory Report
        <sup>
          <a href="https://unfccc.int/documents/225486">[1]</a>
        </sup>{' '}
        on fuel used for domestic and international aviation and data on
        passenger statistics reported by Statistics Iceland
        <sup>
          <a href="https://px.hagstofa.is/pxis/pxweb/is/Atvinnuvegir/Atvinnuvegir__ferdathjonusta__farthegar/SAM02001.px/?rxid=d46e1001-4f64-4a9d-85bf-60f41328122b">
            [2]
          </a>
        </sup>{' '}
        comprise the data used to find the average Icelanders aviation
        footprint. Included emissions are domestic fuel use and a fraction of
        the international fuel usage equal to the ratio of Icelandic passengers.
      </p>
      <p>
        Part of aviation emissions produced by Icelanders occur outside of
        Iceland. The average calculation does not include these emissions. That
        is why NetZero has a detailed aviation calculator for more precise
        estimates.
      </p>
      <h4>Detailed Calculations</h4>
      <p>
        The calculator uses emission factors for flights released by the UK
        Department for Business, Energy, and Industrial Strategy
        <sup>
          <a href="https://www.gov.uk/government/publications/greenhouse-gas-reporting-conversion-factors-2020">
            [3]
          </a>
        </sup>
        . The passenger's seat class and the orthodromic distance between the
        departure and arrival cities are used to calculate the emissions. When
        used, all aviation fuel emissions from the National Inventory Report
        <sup>
          <a href="https://unfccc.int/documents/225486">[1]</a>
        </sup>{' '}
        are removed from the user's total emissions.
      </p>

      <h2>Automobile Use</h2>
      <h4>Average Icelander</h4>
      <p>
        Data from the National Inventory Report
        <sup>
          <a href="https://unfccc.int/documents/225486">[1]</a>
        </sup>{' '}
        on fuel usage by cars is used to find the average Icelander's car
        footprint.
      </p>
      <h4>Detailed Calculations</h4>
      <p>
        The calculator uses emission factors for passenger vehicles released by
        the UK Department for Business, Energy, and Industrial Strategy
        <sup>
          <a href="https://www.gov.uk/government/publications/greenhouse-gas-reporting-conversion-factors-2020">
            [3]
          </a>
        </sup>
        . The car's size and energy source and the distance traveled are used to
        calculate the emissions. When used, all NIR
        <sup>
          <a href="https://unfccc.int/documents/225486">[1]</a>
        </sup>{' '}
        reported car fuel emissions are removed from the user's emissions total.
        Emissions from trucks and buses are still included in the aggregate as
        an emissions baseline shared by all Icelanders.
      </p>

      <h2>Diet</h2>
      <h4>Average Icelander</h4>
      <p>
        Data from the National Inventory Report
        <sup>
          <a href="https://unfccc.int/documents/225486">[1]</a>
        </sup>{' '}
        on agriculture and fuel used for fishing along with statistics on
        domestic fish consumption reported by Statistics Iceland
        <sup>
          <a href="https://px.hagstofa.is/pxis/pxweb/is/Atvinnuvegir/Atvinnuvegir__sjavarutvegur__aflatolur__radstofun_afla_fiskveidisvaedi/SJA09101.px/?rxid=eec90229-275f-4d9e-925c-b7dafa83ee40">
            [4]
          </a>
        </sup>{' '}
        comprise the data used to find the average Icelanders diet footprint.
      </p>
      <h4>Detailed Calculations</h4>
      <p>
        The calculator assumes that the average Icelander eats red meat three
        times a week, white meat four times a week and fish twice a week.
        Furthermore it assumes that 90% of people eat meat and 95% of people eat
        fish. These assumptions are not scientifically based and will hopefully
        be fixed soon, when the The Directorate of Health releases it's study on
        the diet of Icelanders
        <sup>
          <a href="https://www.landlaeknir.is/um-embaettid/frettir/frett/item37911/konnun-a-mataraedi-islendinga-%E2%80%A8embaetti-landlaeknis-og-rannsoknastofa-i-naeringarfraedi-hvetja-landsmenn-til-thatttoku">
            [5]
          </a>
        </sup>
        . These assumptions are used to create emission per portion factors
        using the reported animal husbandry and fishing emissions from the
        National Inventory Report
        <sup>
          <a href="https://unfccc.int/documents/225486">[1]</a>
        </sup>
        .
      </p>
      <h2>Animal Ownership</h2>
      <h4>Average Icelander</h4>
      <p>
        Data from the National Inventory Report
        <sup>
          <a href="https://unfccc.int/documents/225486">[1]</a>
        </sup>{' '}
        on emissions from horses was used to find an average per person.
      </p>
      <h4>Detailed Calculations</h4>
      <p>
        Data from the National Inventory Report
        <sup>
          <a href="https://unfccc.int/documents/225486">[1]</a>
        </sup>{' '}
        on emissions from horses and data from Statistics Iceland on the number
        of horses in Iceland
        <sup>
          <a href="https://px.hagstofa.is/pxis/pxweb/is/Atvinnuvegir/Atvinnuvegir__landbunadur__landbufe/LAN10102.px/?rxid=0ce847f6-3e79-4413-8b85-6c031d2cb863">
            [6]
          </a>
        </sup>{' '}
        are used to find an emission factor to calculate emissions per horse.
      </p>

      <h2>Waste</h2>
      <h4>Average Icelander</h4>
      <p>
        Data used to calculate waste sourced from the National Inventory Report
        <sup>
          <a href="https://unfccc.int/documents/225486">[1]</a>
        </sup>{' '}
        and based on average per person.
      </p>

      <h2>Services, Industry and Transportation of Goods</h2>
      <h4>Average Icelander</h4>
      <p>
        Data used to calculate other factors sourced from the National Inventory
        Report
        <sup>
          <a href="https://unfccc.int/documents/225486">[1]</a>
        </sup>{' '}
        and based on average per person.
      </p>
    </div>
  );
}
