import React from 'react';

import style from './Payment.module.css';
import { Card } from 'antd';
import Meta from 'antd/lib/card/Meta';
import useCalculationResult from '../../hooks/useCalculationResult';
import { round } from 'lodash';
import { OffsetMethod, offsetPrices } from './Payment';

export default function OffsetSelection({
  offsetPercent,
  selectedMethod,
  setSelectedMethods,
}: {
  offsetPercent: number;
  selectedMethod: OffsetMethod;
  setSelectedMethods: (method: OffsetMethod) => void;
}) {
  const { total } = useCalculationResult();
  return (
    <section>
      <h3>How Do You Want to Offset?</h3>
      <div className={style.cardRow}>
        <button
          className={`${style.cardWrap} ${
            selectedMethod === OffsetMethod.ETS ? style.active : ''
          }`}
          onClick={() => setSelectedMethods(OffsetMethod.ETS)}
        >
          <Card
            bordered={false}
            style={{ width: 300 }}
            cover={<img alt="Windmills" src="/img/power-trees-sm.jpg" />}
            // actions={[
            //   `ISK ${offsetPrices[OffsetMethod.ETS].toLocaleString(
            //     'en'
            //   )} pr. tonne`,
            // ]}
          >
            <Meta
              title="ETS Credits"
              description="ETS credits place a cap on total emissions in Europe. By purchasing credits you reduce the amount available in the scheme"
            />
          </Card>
        </button>

        <button
          className={`${style.cardWrap} ${
            selectedMethod === OffsetMethod.Carbfix ? style.active : ''
          }`}
          onClick={() => setSelectedMethods(OffsetMethod.Carbfix)}
        >
          <Card
            style={{ width: 300 }}
            cover={<img alt="Carbon Stored in Rock" src="/img/rock.jpg" />}
            // actions={[
            //   `ISK ${offsetPrices[OffsetMethod.Carbfix].toLocaleString(
            //     'en'
            //   )} pr. tonne`,
            // ]}
          >
            <Meta
              title="Convert CO₂ Into Rock"
              description="Carbon capture and storage in solid form guarantees carbon reduction in the atmosphere"
            />
          </Card>
        </button>
      </div>
      {/* {total ? (
        <h4 className="t-center">
          Your Total:{' '}
          {round(
            (offsetPercent * total * offsetPrices[selectedMethod]) / 100 / 12
          ).toLocaleString('en')}{' '}
          ISK per month
        </h4>
      ) : null} */}
    </section>
  );
}
