import React from 'react';
import { Steps } from 'antd';
import useCalculationResult from '../hooks/useCalculationResult';
import useCalculatorState from '../hooks/useCalculatorState';

import style from './CalculatorSteps.module.css';

const { Step } = Steps;

type CalculatorSteps = {
  step?: number;
};

export default function CalculatorSteps({ step = 1 }) {
  const { total } = useCalculationResult();
  const { state } = useCalculatorState();

  console.log(step);
  return (
    <div className={style.wrap}>
      <Steps
        key="1"
        size="small"
        current={step}
        className="site-navigation-steps"
        style={{
          maxWidth: 900,
        }}
      >
        <Step
          title="Household"
          description={
            <>
              {state.adults} {state.adults === 1 ? 'Adult, ' : 'Adults, '}
              {state.children} {state.children === 1 ? 'Child' : 'Children'}
            </>
          }
          status="finish"
        />
        <Step
          title={
            state.adults + state.children === 1
              ? 'Your Emissions'
              : 'Household Emissions'
          }
          status={step === 1 ? 'process' : 'finish'}
          description={total ? `${total} tons of CO₂e per year` : ''}
        />
        <Step
          title="Offset Emissions"
          status={step === 1 ? 'wait' : 'process'}
          disabled={step < 2}
        />
      </Steps>
    </div>
  );
}
