import React from 'react';
import Spacer from '../../components/Spacer';
import { Table, Button } from 'antd';
import { useQuery, gql } from '@apollo/client';
import { OffsetMethod } from './Payment';
import { RoutesEnum } from '../../App';
import { Link } from 'react-router-dom';

const GET_ORDERS = gql`
  query getOrders {
    orders {
      orderList {
        adults
        children
        id
        tCO2e
        offsetMethod
      }
    }
  }
`;

const columns = [
  {
    title: 'Transaction Id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Household',
    dataIndex: 'household',
    key: 'household',
  },
  {
    title: 'Offset (CO₂e)',
    dataIndex: 'tCO2e',
    key: 'tCO2e',
  },
  {
    title: 'Offset Method',
    dataIndex: 'offsetMethod',
    key: 'offsetMethod',
  },
];

type Order = {
  adults: number;
  children: number;
  id: string;
  tCO2e: number;
  offsetMethod: OffsetMethod;
};

type OrderListEntry = {
  id: string;
  tCO2e: number;
  household: string;
  offsetMethod: string;
  key: string;
};

export default function Transactions() {
  const { loading, data } = useQuery(GET_ORDERS);

  const orders: OrderListEntry[] = (data?.orders?.orderList || []).map(
    (d: Order) => ({
      id: d.id,
      tCO2e: d.tCO2e,
      offsetMethod:
        d.offsetMethod === OffsetMethod.ETS ? (
          'ETS'
        ) : (
          <>
            <abbr title="Carbon Capture and Storage">CCS</abbr> in Rock
            <span role="img" aria-label="guitar">
              🎸
            </span>
          </>
        ),
      key: d.id,
      household: [
        d.adults === 1
          ? '1 Adult'
          : d.adults > 1
          ? `${d.adults} Adults`
          : false,
        d.children === 1
          ? '1 Child'
          : d.children > 1
          ? `${d.children} Children`
          : false,
      ]
        .filter((v) => v)
        .join(', '),
    })
  );

  return (
    <section>
      <div className="content">
        <h2>Purchases of Offsets</h2>
        <p className="t-lead">
          Here you can see a list of all purchases that have been made through
          NetZero.
        </p>
        <p>
          If you have made your order, you can make sure it's counted, and being
          offset. To make an order, start by calculating your footprint:
        </p>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Link to={RoutesEnum.Calculator}>
            <Button type="primary">Calculate My Footprint</Button>
          </Link>
        </div>
        <Spacer spaces={4} />
      </div>
      <div style={{ maxWidth: 1200, margin: '0 auto', padding: '0 2rem' }}>
        <Table<OrderListEntry>
          dataSource={orders}
          columns={columns}
          loading={loading}
        />
      </div>
    </section>
  );
}
