import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { ApolloProvider } from '@apollo/client';

import Home from './pages/Home';
import Header from './components/Header';
import Calculator from './pages/Calculator';
import { apolloClient } from '.';
import {
  useCreateCalculatorState,
  useCreateCalculatorContext,
} from './hooks/useCalculatorState';
import Rationale from './pages/Rationale';
import Payment from './pages/Payment/Payment';
import ScrollToTop from './components/ScrollToTop';
import Transactions from './pages/Payment/Transactions';

export const CalculatorContext = useCreateCalculatorContext();

export enum RoutesEnum {
  Home = '/',
  Calculator = '/calculating',
  Payment = '/reducing-your-footprint',
  Rationale = '/finding-your-footprint',
  Transactions = '/list-of-offset-orders',
}

export default function App() {
  const calculatorStuff = useCreateCalculatorState();
  return (
    <Router>
      <ApolloProvider client={apolloClient}>
        <CalculatorContext.Provider value={calculatorStuff}>
          <ScrollToTop />
          <Header />
          <Switch>
            <Route path={RoutesEnum.Calculator}>
              <Calculator />
            </Route>
            <Route path={RoutesEnum.Payment}>
              <Payment />
            </Route>
            <Route path={RoutesEnum.Transactions}>
              <Transactions />
            </Route>
            <Route path={RoutesEnum.Rationale}>
              <Rationale />
            </Route>
            <Route path={RoutesEnum.Home}>
              <Home />
            </Route>
          </Switch>
        </CalculatorContext.Provider>
      </ApolloProvider>
    </Router>
  );
}
