import React from 'react';
import useCalculationResult from '../../hooks/useCalculationResult';
import { round } from 'lodash';
import { Slider } from 'antd';

const marks = {
  50: 'Some of it',
  100: 'All of it',
  150: 'Even more',
};
function formatter(value?: number) {
  return `${value}%`;
}
type OffsetAmountPickerProps = {
  offsetPercent: number;
  setOffsetPercent: (offsetPercent: number) => void;
};
export default function OffsetAmountPicker({
  offsetPercent,
  setOffsetPercent,
}: OffsetAmountPickerProps) {
  const { total } = useCalculationResult();
  return (
    <section>
      <h3>How Much Do You Want To Offset?</h3>
      {total ? (
        <p className="t-lead t-center">
          <strong>{round(total * (offsetPercent / 100), 1)} tCO₂e</strong>
        </p>
      ) : null}
      <Slider
        value={offsetPercent}
        onChange={setOffsetPercent}
        marks={marks}
        step={5}
        min={50}
        max={150}
        tipFormatter={formatter}
      />

      <h5 className="t-center" style={{ marginTop: '3rem' }}>
        Your footprint after offsetting:
      </h5>
      {total ? (
        <p className="t-lead t-center">
          {round(total - total * (offsetPercent / 100), 1)} tCO₂e
        </p>
      ) : null}
    </section>
  );
}
