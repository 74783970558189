import React, { useContext } from 'react';
import { PageHeader, Menu } from 'antd';
import { Steps } from 'antd';

import styles from './Header.module.css';
import { Link } from 'react-router-dom';
import useCalculationResult from '../hooks/useCalculationResult';
import { CalculatorContext, RoutesEnum } from '../App';

const { Step } = Steps;

export default function Header() {
  return (
    <header className={styles.appHeaderWrap}>
      <PageHeader
        className={styles.appHeader}
        // onBack={() => null}
        title={<Link to="/">NetZero</Link>}
        subTitle="Verifiable Carbon Reduction"
        extra={[
          <Menu
            key={1}
            onClick={console.log}
            selectedKeys={[]}
            mode="horizontal"
          >
            <Menu.Item key="mail">
              <Link to={RoutesEnum.Transactions}>Offsets Purchased</Link>
            </Menu.Item>

            {/* <Menu.Item key="app">Info</Menu.Item> */}
            {/* <Menu.Item key="about">Um NetZero</Menu.Item> */}
            {/* <Menu.Item key="language">English</Menu.Item> */}
          </Menu>,
        ]}
      />
    </header>
  );
}

export const CalculatorHeader = ({ currentStep }) => {
  const { state } = useContext(CalculatorContext);
  const { tCo2e } = useCalculationResult();

  return (
    <header className={styles.appHeaderWrap}>
      <PageHeader
        className={styles.appHeader}
        // onBack={() => null}
        title={<Link to="/">NetZero</Link>}
        extra={[
          <Steps
            key="1"
            size="small"
            current={1}
            // onChange={setCurrentStep}
            className="site-navigation-steps"
            style={{ maxWidth: '80%', margin: '1rem auto 2rem' }}
          >
            <Step
              title="Fólk"
              description={
                <>
                  {state.adults} Fullorðnir, {state.children} Börn
                </>
              }
              status="finish"
            />
            <Step
              title="Þín notkun"
              // subTitle="00:01:02"
              status="process"
              description={tCo2e ? `${tCo2e} tonn CO₂ ígildi` : ''}
            />
            <Step
              title="Jafnaðu þig"
              status="wait"
              description="This is a description."
            />
          </Steps>,
        ]}
      />
    </header>
  );
};
