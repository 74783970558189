import { useContext, useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { isUndefined, round } from 'lodash';

import { CalculatorContext } from '../App';
import { EmissionCategory, DietInfo } from './useCalculatorState';
import useUserId from './useUserId';

const GET_CALCULATION = gql`
  query getCalculation(
    $adults: Int!
    $children: Int!
    $id: ID!
    $aviation: [Aviation!]
    $automobiles: [Automobile!]
    $diet: Diet
    $animals: AnimalOwnership
  ) {
    emissions {
      calculate(
        id: $id
        adults: $adults
        children: $children
        aviation: $aviation
        automobiles: $automobiles
        diet: $diet
        animals: $animals
      ) {
        id
        total
        transportation {
          aviation
          automobiles
          baseline
        }
        diet {
          total
          dairy
          redMeat
          whiteMeat
          fish
          baseline
        }
        animals {
          total
          horses
          baseline
        }
        industry {
          total
          baseline
        }
        waste {
          total
          baseline
        }
      }
    }
  }
`;

type CalculationResult = {
  total: null | number;
  aviation: null | number;
  automobiles: null | number;
  transportBase: null | number;
  diet: null | number;
  animals: null | number;
  industry: null | number;
  waste: null | number;
  transportIndustryServices: null | number;
};

const transformDietState = (diet: DietInfo) => ({
  ...diet,
  consumesDairy: !!diet.consumesDairy,
});

export default function useCalculationResult() {
  const { state } = useContext(CalculatorContext);
  const id = useUserId();

  const {
    adults,
    children,
    aviation,
    automobiles,
    diet,
    animals,
    openPanels,
  } = state;

  const { data, ...rest } = useQuery(GET_CALCULATION, {
    variables: {
      adults,
      children,
      aviation: openPanels.includes(EmissionCategory.Aviation)
        ? aviation.filter(
            ({ from, to, numberOfSeats }) => from && to && numberOfSeats
          )
        : null,
      automobiles: openPanels.includes(EmissionCategory.Automobiles)
        ? automobiles.map(
            ({ kmDriven, periodMultiplicationFactor, ...rest }) => ({
              kmDrivenPerYear: kmDriven * periodMultiplicationFactor,
              ...rest,
            })
          )
        : null,
      id,
      diet: openPanels.includes(EmissionCategory.Diet)
        ? transformDietState(diet)
        : null,
      animals: openPanels.includes(EmissionCategory.Animals) ? animals : null,
    },
  });

  const [cachedResult, setCachedResult] = useState<CalculationResult>({
    total: null,
    aviation: null,
    automobiles: null,
    transportBase: null,
    diet: null,
    animals: null,
    industry: null,
    waste: null,
    transportIndustryServices: null,
  });

  useEffect(() => {
    const results = data?.emissions?.calculate;

    if (!isUndefined(results)) {
      const total: number | null = round(results?.total, 2);
      const aviation: number | null = round(
        results?.transportation?.aviation,
        2
      );
      const automobiles: number | null = round(
        results?.transportation?.automobiles,
        2
      );
      const transportBase: number | null = round(
        results?.transportation?.baseline,
        2
      );
      const diet: number | null = round(results?.diet?.total, 2);
      const animalsTotal: number | null = round(results?.animals?.total, 2);
      const industry: number | null = round(results?.industry?.total, 2);
      const waste: number | null = round(results?.waste?.total, 2);

      const transportIndustryServices =
        transportBase && industry ? round(transportBase + industry) : null;

      setCachedResult({
        total: total,
        aviation: aviation,
        automobiles: automobiles,
        transportBase: transportBase,
        diet: diet,
        animals: animalsTotal,
        industry: industry,
        waste: waste,
        transportIndustryServices: transportIndustryServices,
      });
    }
  }, [data]);

  return {
    ...cachedResult,
    ...rest,
  };
}
