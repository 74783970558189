import produce from 'immer';
import { useReducer, Dispatch, createContext, useContext } from 'react';
import { CalculatorContext } from '../App';

export enum SeatClass {
  First = 'FirstClass',
  Business = 'BusinessClass',
  Economy = 'EconomyClass',
}

type AviationInfo = {
  from: string;
  to: string;
  seatClass: SeatClass;
  numberOfSeats?: number;
  returnFlight: boolean;
};
export enum AutomobileSize {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
}

export enum AutomobileFuelType {
  Petrol = 'Petrol',
  Diesel = 'Diesel',
  Hybrid = 'Hybrid',
  Biomethane = 'Biomethane',
  // CNG = 'cng' // Compressed Natural Gas - Methane
  // LPG - Liquefied petroleum gas (Autogas) - Not applicable in Iceland
  // Unknown = 'Unknown',
  PHEV = 'PluginHybrid',
  Electric = 'Electric',
}

export type AutomobileInfo = {
  automobileSize: AutomobileSize;
  fuelType: AutomobileFuelType;
  kmDriven: number;
  periodMultiplicationFactor: number;
};

export type DietInfo = {
  consumesDairy: 0 | 1;
  redMeatPPW: number;
  whiteMeatPPW: number;
  fishPPW: number;
};

export enum EmissionCategory {
  Aviation,
  Automobiles,
  Diet,
  Animals,
}

export type CalculatorState = {
  adults: number;
  children: number;
  personEquivalent: number;
  automobiles: readonly AutomobileInfo[];
  aviation: readonly AviationInfo[];
  diet: DietInfo;
  animals: {
    horses: number;
  };
  openPanels: readonly EmissionCategory[];
};

export const initialCalculatorState: CalculatorState = {
  adults: 1,
  children: 0,
  personEquivalent: 1,
  automobiles: [],
  aviation: [],
  diet: {
    consumesDairy: 1,
    redMeatPPW: 3,
    whiteMeatPPW: 4,
    fishPPW: 2,
  },
  animals: {
    horses: 0,
  },
  openPanels: [],
};

export const getInitialAviationValues = (people: number) => ({
  from: '',
  to: '',
  seatClass: SeatClass.Economy,
  numberOfSeats: people,
  returnFlight: true,
});

export const initialAutomobileValues = {
  automobileSize: AutomobileSize.Medium,
  fuelType: AutomobileFuelType.Petrol,
  kmDriven: 12000,
  periodMultiplicationFactor: 1,
};
/************ Actions & Action Creators ************/

enum ActionTypes {
  SetPersons,
  SetCategoryCustomization,
  AddAviationLine,
  RemoveAviationLine,
  UpdateAviationLine,
  AddAutomobileLine,
  RemoveAutomobileLine,
  UpdateAutomobileLine,
  SetPeriodMultiplicationFactor,
  SetDietInfo,
  SetNumberOfHorses,
}

type SetPersonsAction = {
  type: ActionTypes.SetPersons;
  adults: number;
  children: number;
};
const updatePeople = (dispatch: Dispatch<Action>) => ({
  adults,
  children,
}: {
  adults: number;
  children: number;
}) => {
  dispatch({
    type: ActionTypes.SetPersons,
    adults,
    children,
  });
};

type SetCategoryCustomizationAction = {
  type: ActionTypes.SetCategoryCustomization;
  category: EmissionCategory;
  value: boolean;
};

const setCategoryCustomization = (dispatch: Dispatch<Action>) => (
  category: EmissionCategory,
  value: boolean
) => {
  dispatch({
    type: ActionTypes.SetCategoryCustomization,
    category,
    value,
  });
};

type AddAviationLineAction = {
  type: ActionTypes.AddAviationLine;
};
const addAviationLine = (dispatch: Dispatch<Action>) => () => {
  dispatch({
    type: ActionTypes.AddAviationLine,
  });
};

type RemoveAviationLineAction = {
  type: ActionTypes.RemoveAviationLine;
  index: number;
};
const removeAviationLine = (dispatch: Dispatch<Action>) => (index: number) => {
  dispatch({
    type: ActionTypes.RemoveAviationLine,
    index,
  });
};

type UpdateAviationLineAction = {
  type: ActionTypes.UpdateAviationLine;
  index: number;
  from?: string;
  to?: string;
  seatClass?: SeatClass;
  numberOfSeats?: number;
  returnFlight?: boolean;
};
const updateAviationLine = (dispatch: Dispatch<Action>) => ({
  index,
  from,
  to,
  seatClass,
  numberOfSeats,
  returnFlight,
}: {
  index: number;
  from?: string;
  to?: string;
  seatClass?: SeatClass;
  numberOfSeats?: number;
  returnFlight?: boolean;
}) => {
  dispatch({
    type: ActionTypes.UpdateAviationLine,
    index,
    from,
    to,
    seatClass,
    numberOfSeats,
    returnFlight,
  });
};

type AddAutomobileLineAction = {
  type: ActionTypes.AddAutomobileLine;
};
const addAutomobileLine = (dispatch: Dispatch<Action>) => () => {
  dispatch({
    type: ActionTypes.AddAutomobileLine,
  });
};

type RemoveAutomobileLineAction = {
  type: ActionTypes.RemoveAutomobileLine;
  index: number;
};
const removeAutomobileLine = (dispatch: Dispatch<Action>) => (
  index: number
) => {
  console.log('remove', index);
  dispatch({
    type: ActionTypes.RemoveAutomobileLine,
    index,
  });
};

type UpdateAutomobileLineAction = {
  type: ActionTypes.UpdateAutomobileLine;
  index: number;
  automobileSize?: AutomobileSize;
  fuelType?: AutomobileFuelType;
  kmDriven?: number;
};
const updateAutomobileLine = (dispatch: Dispatch<Action>) => ({
  index,
  automobileSize,
  fuelType,
  kmDriven,
}: {
  index: number;
  automobileSize?: AutomobileSize;
  fuelType?: AutomobileFuelType;
  kmDriven?: number;
}) => {
  dispatch({
    type: ActionTypes.UpdateAutomobileLine,
    index,
    automobileSize,
    fuelType,
    kmDriven,
  });
};

type SetPeriodMultiplicationFactorAction = {
  type: ActionTypes.SetPeriodMultiplicationFactor;
  value: number;
  index: number;
};

const setPeriodMultiplicationFactor = (dispatch: Dispatch<Action>) => (
  index: number,
  value: number
) => {
  dispatch({
    type: ActionTypes.SetPeriodMultiplicationFactor,
    value,
    index,
  });
};

type SetDietInfoAction = {
  type: ActionTypes.SetDietInfo;
  diet: DietInfo;
};

const setDietInfo = (dispatch: Dispatch<Action>) => (diet: DietInfo) => {
  dispatch({
    type: ActionTypes.SetDietInfo,
    diet,
  });
};

type SetNumberOfHorsesAction = {
  type: ActionTypes.SetNumberOfHorses;
  horses: number;
};

const setNumberOfHorses = (dispatch: Dispatch<Action>) => (horses: number) => {
  dispatch({
    type: ActionTypes.SetNumberOfHorses,
    horses,
  });
};

export type Action =
  | SetPersonsAction
  | SetCategoryCustomizationAction
  | AddAviationLineAction
  | RemoveAviationLineAction
  | UpdateAviationLineAction
  | AddAutomobileLineAction
  | RemoveAutomobileLineAction
  | UpdateAutomobileLineAction
  | SetPeriodMultiplicationFactorAction
  | SetDietInfoAction
  | SetNumberOfHorsesAction;

const reducer = produce((draft: CalculatorState, action: Action) => {
  switch (action.type) {
    case ActionTypes.SetPersons:
      draft.adults = action.adults;
      draft.children = action.children;
      draft.personEquivalent = action.adults + 0.5 * action.children;
      break;
    case ActionTypes.SetCategoryCustomization:
      if (action.value) {
        draft.openPanels = Array.from(
          new Set(draft.openPanels).add(action.category)
        );
      } else {
        const index = draft.openPanels.indexOf(action.category);
        console.log({ index });
        if (index > -1) {
          draft.openPanels = draft.openPanels
            .slice(0, index)
            .concat(draft.openPanels.slice(index + 1));
        }
        console.log([...draft.openPanels]);
      }
      break;
    case ActionTypes.AddAviationLine:
      draft.aviation = [
        ...draft.aviation,
        getInitialAviationValues(draft.adults + draft.children),
      ];
      break;
    case ActionTypes.RemoveAviationLine:
      let flights = [...draft.aviation];
      flights.splice(action.index, 1);
      draft.aviation = flights;
      break;
    case ActionTypes.UpdateAviationLine:
      // This is probably a good place to do some cleanup 😅
      const line = draft.aviation[action.index];

      line.from = action.from || line.from;
      line.to = action.to || line.to;
      line.seatClass = action.seatClass || line.seatClass;
      line.numberOfSeats = action.numberOfSeats || line.numberOfSeats;
      line.returnFlight = !(typeof action.returnFlight === 'undefined')
        ? (action.returnFlight as boolean)
        : line.returnFlight;

      break;
    case ActionTypes.AddAutomobileLine:
      draft.automobiles = [...draft.automobiles, initialAutomobileValues];
      break;
    case ActionTypes.RemoveAutomobileLine:
      let autos = [...draft.automobiles];
      autos.splice(action.index, 1);
      draft.automobiles = autos;
      break;
    case ActionTypes.UpdateAutomobileLine:
      const autoLine = draft.automobiles[action.index];
      autoLine.automobileSize =
        action.automobileSize || autoLine.automobileSize;
      autoLine.fuelType = action.fuelType || autoLine.fuelType;
      autoLine.kmDriven = action.kmDriven || autoLine.kmDriven;
      break;
    case ActionTypes.SetPeriodMultiplicationFactor:
      draft.automobiles[action.index].periodMultiplicationFactor = action.value;
      break;
    case ActionTypes.SetDietInfo:
      draft.diet = action.diet;
      break;
    case ActionTypes.SetNumberOfHorses:
      draft.animals.horses = action.horses || 0;
      break;
  }
});

export default function useCalculatorState() {
  return useContext(CalculatorContext);
}

const createActionCreators = (dispatch: Dispatch<Action>) => ({
  updatePeople: updatePeople(dispatch),
  setCategoryCustomization: setCategoryCustomization(dispatch),
  addAviationLine: addAviationLine(dispatch),
  removeAviationLine: removeAviationLine(dispatch),
  updateAviationLine: updateAviationLine(dispatch),
  addAutomobileLine: addAutomobileLine(dispatch),
  removeAutomobileLine: removeAutomobileLine(dispatch),
  updateAutomobileLine: updateAutomobileLine(dispatch),
  setPeriodMultiplicationFactor: setPeriodMultiplicationFactor(dispatch),
  setDietInfo: setDietInfo(dispatch),
  setNumberOfHorses: setNumberOfHorses(dispatch),
});

export function useCreateCalculatorState() {
  const [state, dispatch] = useReducer(reducer, initialCalculatorState);

  return {
    ...createActionCreators(dispatch),
    state,
  };
}

const mockDispatch = (action: Action) => {};

export function useCreateCalculatorContext() {
  return createContext({
    ...createActionCreators(mockDispatch),
    state: initialCalculatorState,
  });
}
