import React from 'react';
import { Form, Input, Checkbox, Button } from 'antd';
import { gql, useMutation, MutationResult } from '@apollo/client';
import Confetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';
import { v4 as uuid } from 'uuid';

import useCalculatorState from '../../hooks/useCalculatorState';
import useCalculationResult from '../../hooks/useCalculationResult';
import { Link } from 'react-router-dom';
import { RoutesEnum } from '../../App';
import { OffsetMethod } from './Payment';

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: {
    ...layout.wrapperCol,
    offset: layout.labelCol.span,
  },
};

const PLACE_ORDER = gql`
  mutation($input: OrderInput!) {
    orders {
      placeOrder(input: $input) {
        id
        price
        tCO2e
      }
    }
  }
`;

type OrderFunctionProps = {
  offsetPercent: number;
  offsetMethod: OffsetMethod;
  email: string;
};

function usePlaceOrder(): [
  (props: OrderFunctionProps) => void,
  MutationResult<any>
] {
  const id = uuid();
  const { total } = useCalculationResult();
  const { state } = useCalculatorState();
  const { adults, children } = state;

  const [orderMutation, data] = useMutation(PLACE_ORDER, {
    onError: (e) => {
      console.log(e);
    },
  });

  return [
    ({ offsetPercent, email, offsetMethod }: OrderFunctionProps) =>
      orderMutation({
        variables: {
          input: {
            id,
            adults,
            children,
            tCO2e: (offsetPercent / 100) * (total || 10),
            email,
            offsetMethod,
          },
        },
      }),
    data,
  ];
}

type RegisterForUpdatesProps = {
  offsetPercent: number;
  offsetMethod: OffsetMethod;
};

export default function RegisterForUpdates({
  offsetPercent,
  offsetMethod,
}: RegisterForUpdatesProps) {
  const [placeOrder, { loading, data }] = usePlaceOrder();
  const { width, height } = useWindowSize();

  console.log(data, !!data);
  return (
    <section>
      <h3>Register For Updates</h3>
      <p style={{ fontSize: 18 }}>
        Thank you for taking action and becoming NetZero. We haven't starting
        taking orders, but if you leave your email address we'll be in touch
        when we're ready to launch{' '}
        <span role="img" aria-label="Rocket ready to launch">
          🚀
        </span>
      </p>
      {!data ? (
        <Form
          onFinish={({ email }) =>
            placeOrder({ offsetPercent, offsetMethod, email: email as string })
          }
        >
          <Form.Item name="email" label="Email" {...layout}>
            <Input
              placeholder="e.g. yourname@example.com"
              type="email"
              size="large"
            />
          </Form.Item>

          <Form.Item
            {...tailLayout}
            name="allowContact"
            valuePropName="checked"
            initialValue={true}
          >
            <Checkbox>NetZero can send me updates about its product</Checkbox>
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button
              style={{ width: 250 }}
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Register
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <>
          <div
            style={{
              position: 'fixed',
              zIndex: 10000,
              pointerEvents: 'none',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
          >
            <Confetti
              numberOfPieces={700}
              recycle={false}
              width={width}
              height={height}
              tweenDuration={50000}
            />
          </div>
          <h4>
            Thank you for registering!{' '}
            <span role="img" aria-label="confetti">
              🎉
            </span>
          </h4>
          <p>
            We can now pretend that you've ordered an offset for{' '}
            {data.orders.placeOrder.tCO2e} tons CO₂e.
          </p>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Link to={RoutesEnum.Transactions}>
              <Button type="primary" size="large">
                See list of all orders
              </Button>
            </Link>
          </div>
        </>
      )}
    </section>
  );
}
