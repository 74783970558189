// Gunnar
import React from 'react';
import useCalculationResult from '../hooks/useCalculationResult';
import DetailedForm from '../components/DetailedForm';
import { Button, Popover } from 'antd';

import { Link } from 'react-router-dom';
import { RoutesEnum } from '../App';

import CalculatorSteps from '../components/CalculatorSteps';
import Spacer from '../components/Spacer';

const co2Explain = (
  <p style={{ maxWidth: 450 }}>
    A carbon dioxide equivalent or CO2 equivalent, is a metric measure used to
    compare the emissions from various greenhouse gases on the basis of their
    global-warming potential, by converting amounts of other gases to the
    equivalent amount of carbon dioxide with the same global warming potential.
  </p>
);

export default function Calculator() {
  const { total } = useCalculationResult();

  return (
    <div>
      <CalculatorSteps step={1} />

      <div style={{ maxWidth: 900, margin: '0 auto', padding: '0 2rem' }}>
        <h1>
          Calculate Your
          <br />
          Carbon Footprint
        </h1>
        <p>
          Your initial results are <b>{total}</b> tons of{' '}
          <Popover
            placement="bottom"
            content={co2Explain}
            title="CO₂ Equivalent"
          >
            <span
              style={{
                textDecoration: 'underline',
                textDecorationStyle: 'dotted',
              }}
            >
              CO₂ equivalent
            </span>
          </Popover>{' '}
          per year.
        </p>

        <Spacer spaces={4} />

        <DetailedForm />
        <br></br>
        <br></br>

        <p className="t-lead t-center">
          Your emissions are {total} tons of CO₂ equivalent per year
        </p>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Link to={RoutesEnum.Payment}>
            <Button type="primary" size="large" style={{ margin: '0 auto' }}>
              Offset your footprint
            </Button>
          </Link>
        </div>

        <Spacer spaces={4} />
        <p>
          Calculations are based on data from{' '}
          <a href="https://www.ust.is/">The Environment Agency of Iceland</a>,{' '}
          <a href="https://hagstofa.is/">Statistics Iceland</a>,{' '}
          <a href="https://www.skra.is/">Registers Iceland</a>,{' '}
          <a href="https://www.samgongustofa.is/">
            Icelandic Transport Authority
          </a>{' '}
          and{' '}
          <a href="https://www.gov.uk/government/organisations/department-for-business-energy-and-industrial-strategy">
            the UK Department for Business, Energy & Industrial Strategy
          </a>
          .{/* https://opingogn.is/pages/notkunarleidbeiningar */}
        </p>
        <p>
          See{' '}
          <Link to={RoutesEnum.Rationale}>further details of calculations</Link>
          .
        </p>
        <Spacer spaces={6} />
      </div>
    </div>
  );
}
