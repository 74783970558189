import React, { useContext } from 'react';
import { Form, Button, InputNumber } from 'antd';

import { CalculatorContext } from '../App';
import styles from './Form.module.css';
import { useHistory } from 'react-router-dom';

export default function BasicCalculatorForm() {
  const { state, updatePeople } = useContext(CalculatorContext);
  const { push } = useHistory();

  const onFinish = (values: { adults: number; children: number }) => {
    updatePeople(values);
    push('/calculating');
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <Form
      name="basic"
      layout="inline"
      initialValues={{ adults: state.adults, children: state.children }}
      className={`${styles.wrapper} ${styles.basicCalcWrap}`}
      // @ts-ignore
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      colon={false}
    >
      <Form.Item label="Adults" name="adults">
        <InputNumber
          className={styles.numberInput}
          size="large"
          min={0}
          max={20}
        />
      </Form.Item>

      <Form.Item label="Children" name="children">
        <InputNumber
          className={styles.numberInput}
          size="large"
          min={0}
          max={20}
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" size="large">
          See My Footprint »
        </Button>
      </Form.Item>
    </Form>
  );
}
